import styled from '@emotion/styled';
import { FullDrawerButtonsProps } from '..';
import { ScreenSizeProps } from '@type/common';

const FullDrawerButtonsB = styled.div<FullDrawerButtonsProps & ScreenSizeProps>`
    display: flex;
    align-items: center;
    gap: 8px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 16px 16px 24px 16px;
    background-color: white;
    margin: 0 auto;
    width: min(100vw, ${({ maxScreenWidth }) => maxScreenWidth}px);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);

    z-index: ${({ zIndex }) => zIndex};
`;

export default FullDrawerButtonsB;
