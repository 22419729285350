import { useDesignComponents } from '@hooks/use-design-components';
import { Design } from '@type/harmony-config';
import { ReactNode } from 'react';
import FullDrawerButtonsA from './designA/full-drawer-buttons';
import FullDrawerButtonsB from './designB/full-drawer-buttons';
import FullDrawerButtonsC from './designC/full-drawer-buttons';
import { useExtension } from '@hooks/use-extension';

export interface FullDrawerButtonsProps {
    children: ReactNode;
    zIndex?: number;
}

const FullDrawerButtons: React.FC<FullDrawerButtonsProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof FullDrawerButtonsA | typeof FullDrawerButtonsB>({
        [Design.DesignA]: FullDrawerButtonsA,
        [Design.DesignB]: FullDrawerButtonsB,
        [Design.DesignC]: FullDrawerButtonsC,
    });
    const { extensionStatus } = useExtension();

    return <Component {...props} maxScreenWidth={extensionStatus?.maxScreenWidth} />;
};

export default FullDrawerButtons;
