import { css } from '@emotion/react';
import { useExtension } from '@hooks/use-extension';
import DrawerHeader from '@legacy/designs/drawer-header';
import { ReactNode } from 'react';

export type HeaderButtonType = 'close' | 'back' | 'home';

type CloseButton = {
    type: 'close';
    onClose: () => void;
};

type BackButton = {
    type: 'back';
    onClose: () => void;
};

type HomeButton = {
    type: 'home';
};

type HeaderButton = CloseButton | BackButton | HomeButton;

export type DrawerHeaderStyleProps = {
    /**
     * @default false
     */
    alignTitleLeft?: boolean;
};

export interface DrawerContentProps extends DrawerHeaderStyleProps {
    children?: ReactNode;
    height?: string;
    padded?: boolean;
    title?: string;
    subtitle?: string;
    headerButton?: HeaderButton;
    logoOnDirect?: boolean;
    hasDivider?: boolean;
    backgroundColor?: string;
    headerColor?: string;
}

const DrawerContent: React.FC<DrawerContentProps> = ({
    children,
    logoOnDirect,
    title,
    headerButton,
    height,
    padded = true,
    subtitle,
    alignTitleLeft = false,
    hasDivider = true,
    backgroundColor = 'white',
    headerColor = 'white',
}) => {
    const { extensionStatus } = useExtension();
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: baseline;
                justify-content: space-between;
                background-color: ${backgroundColor};
                overflow: hidden;
                overflow-y: auto;
                height: ${height};
                max-height: 100vh;
                width: min(100vw, ${extensionStatus?.maxScreenWidth}px);
                .right-icon {
                    margin-left: auto;
                }
            `}
        >
            {(headerButton || title) && (
                <DrawerHeader
                    logoOnDirect={logoOnDirect}
                    title={title}
                    subtitle={subtitle}
                    headerButton={headerButton}
                    alignTitleLeft={alignTitleLeft}
                    hasDivider={hasDivider}
                    backgroundColor={headerColor}
                />
            )}
            <div
                css={css`
                    width: 100%;
                    height: 100%;
                    padding: ${padded ? '0 18px' : '0'};
                    overflow-y: scroll;
                    position: relative;
                `}
            >
                {children}
            </div>
        </div>
    );
};

export default DrawerContent;
