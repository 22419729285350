import FullDrawerButtons from '@legacy/designs/full-drawer-buttons';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import DrawerContent, { DrawerContentProps } from './drawer-content';

interface FullSizeDrawerContentProps extends Omit<DrawerContentProps, 'height'> {
    /**
     * @default false
     */
    alignTitleLeft?: boolean;
    buttons?: ReactNode;
    backgroundColor?: string;
    hasDivider?: boolean;
    padding?: string;
}

const FullSizeDrawerContent: React.FC<FullSizeDrawerContentProps> = ({ children, buttons, backgroundColor, hasDivider = true, padding, ...props }) => {
    return (
        <DrawerContent height="100dvh" padded={false} hasDivider={hasDivider} {...props}>
            <InnerDrawer>
                <ChildrenContainer backgroundColor={backgroundColor} padding={padding}>
                    {children}
                </ChildrenContainer>
                {!!buttons && <FullDrawerButtons>{buttons}</FullDrawerButtons>}
            </InnerDrawer>
        </DrawerContent>
    );
};

export default FullSizeDrawerContent;

const InnerDrawer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const ChildrenContainer = styled.div<{ backgroundColor?: string; padding?: string }>`
    width: 100%;
    padding: ${({ padding }) => padding || '0 0 100px 0'};
    background: ${({ backgroundColor }) => backgroundColor || 'white'};
    min-height: 90vh;
`;
