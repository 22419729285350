import styled from '@emotion/styled';
import { DesignCDropShadow } from '@styles/styled-components';
import { FullDrawerButtonsProps } from '..';
import { ScreenSizeProps } from '@type/common';

const FullDrawerButtonsC = styled(DesignCDropShadow)<FullDrawerButtonsProps & ScreenSizeProps>`
    position: fixed;
    bottom: 0px;

    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
    padding: 8px 16px 24px;
    background-color: white;
    margin: 0 auto;
    width: min(100vw, ${({ maxScreenWidth }) => maxScreenWidth}px);

    z-index: ${({ zIndex }) => zIndex};
`;

export default FullDrawerButtonsC;
