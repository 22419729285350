import styled from '@emotion/styled';
import { FullDrawerButtonsProps } from '..';
import { ScreenSizeProps } from '@type/common';

const FullDrawerButtonsA = styled.div<FullDrawerButtonsProps & ScreenSizeProps>`
    position: fixed;
    bottom: 0px;

    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 72px;
    padding: 0px 16px;
    background-color: white;
    margin: 0 auto;
    width: min(100vw, ${({ maxScreenWidth }) => maxScreenWidth}px);

    z-index: ${({ zIndex }) => zIndex};
`;

export default FullDrawerButtonsA;
